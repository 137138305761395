import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import leftArrow from "./images/leftArrow.svg";
import back from "./images/back.svg";
import curvedArrow from "./images/curvedArrow.svg";
import { MainContext } from "../../../../App";
// import Profile from "./images/Profile.png";

const Discussion = () => {
  const { topicId, discussionId } = useParams();
  const navigate = useNavigate();
  const [discussion, setDiscussion] = useState(false);
  const [answer_, setAnswer_] = useState(null)
  const [comment, setComment] = useState("");
  const [submittedComment, setSubmittedComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingAnswer, setLoadingAnswer] = useState(false);

  const [error, setError] = useState("");
  const [discussionData, setDiscussionData] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [activeParentId, setactiveParentId] = useState(null);
  const [activeChildId, setActiveChildId] = useState(null);

  const [childAnswers, setChildAnswers] = useState([]);
  const [answerComment, setAnswerComment] = useState("");
  const [isEditComment, setIsEditComment] = useState(false);
  const [isEditDiscussion, setIsEditDiscussion] = useState(false);
  const [isEditAnswer, setIsEditAnswer] = useState(false);

  const context =React.useContext(MainContext)
  const [checked, setChecked] = useState(false)

  const token = localStorage.getItem("token");
  const isAdmin = localStorage.getItem("is_admin") === 'true' ? true : false;
  const userId = localStorage.getItem("userId")

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token} `,
  };

  const apiCall = async () => {
    try {
      setLoading(true);
      if (token) {
        const { data: discuss } = await axios.get(
          `${process.env.REACT_APP_QA_API}/discussion/${discussionId}`,
          { headers }
        );
        setDiscussionData(discuss);
        setDiscussion(discuss[0].discussion)
        setChecked(discuss[0].discussion.notify_block)
        setactiveParentId(discuss[0].answers.length > 0 ? discuss[0].answers[0].answer.id : null);
        const { data: topic } = await axios.get(
          `${process.env.REACT_APP_QA_API}/topic/${topicId}`,
          { headers }
        );
        setTopicData(topic);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error.response.status === 401) {
        context.setIsLoggedIn(false)
        localStorage.clear()
        navigate('/')
      }
      setError("Some error occured. Please reload your page.");
    }
  };

  const getChildAnswers = async () => {
    try {
      setLoadingAnswer(true);
      if (token) {
        if (activeParentId != null) {
          const { data } = await axios.get(
            `${process.env.REACT_APP_QA_API}/discussion/answer/${activeParentId}`,
            { headers }
          );
          setChildAnswers(data[0].child_answers);
        }
        setLoadingAnswer(false);
      }
    } catch (error) {
      setLoadingAnswer(false);
      console.error(error);
      if (error.response.status === 401) {
        context.setIsLoggedIn(false)
        localStorage.clear()
        navigate('/')
      }
      setError("Some error occured. Please reload your page.");
    }
  };

  const handleAnswerSubmission = async (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (comment) {
        const formData = {
          content: comment,
          discussion: +discussionId,
        };
        try {
          await axios.post(
            `${process.env.REACT_APP_QA_API}/discussion/answer/`,
            formData,
            { headers }
          );
          setComment("");
          apiCall();
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const handleAnswerSubmissionByButton = async () => {
    if (comment) {
      const formData = {
        content: comment,
        discussion: +discussionId,
      };
      try {
        const dataa = await axios.post(
          `${process.env.REACT_APP_QA_API}/discussion/answer/`,
          formData,
          { headers }
        );
        setComment("");
        apiCall();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChildAnswerSubmission = async (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (answerComment) {
        try {
          if (isEditComment) {
            const formData = {
              content: answerComment,
              discussion: +discussionId,
              parent_answer: activeParentId
            };
            await axios.put(
              `${process.env.REACT_APP_QA_API}/discussion/answer/${activeChildId}`,
              formData,
              { headers }
            );
            setIsEditComment(false)
          } else {
            const formData = {
              content: answerComment,
              discussion: +discussionId,
              parent_answer: activeParentId,
            };
            await axios.post(
              `${process.env.REACT_APP_QA_API}/discussion/answer/`,
              formData,
              { headers }
            );
          }
          setAnswerComment("");
          getChildAnswers();
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const handleChildAnswerSubmissionByButton = async () => {
    if (answerComment) {
      try {
        if (isEditComment) {
          const formData = {
            content: answerComment,
            discussion: +discussionId,
            parent_answer: activeParentId
          };
          await axios.put(
            `${process.env.REACT_APP_QA_API}/discussion/answer/${activeChildId}`,
            formData,
            { headers }
          );
          setIsEditComment(false)
        } else {
          const formData = {
            content: answerComment,
            discussion: +discussionId,
            parent_answer: activeParentId,
          };
          await axios.post(
            `${process.env.REACT_APP_QA_API}/discussion/answer/`,
            formData,
            { headers }
          );
        }
        setAnswerComment("");
        getChildAnswers();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSubAnswerDelete = async (id) => {
    try {
      setLoadingAnswer(true);
      await axios.delete(
        `${process.env.REACT_APP_QA_API}/discussion/answer/${id}`,
        { headers }
      );
      setLoadingAnswer(false);
      setAnswerComment("");
      getChildAnswers()
    } catch (error) {
      setLoadingAnswer(false);
      console.error(error);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    getChildAnswers();
  }, [activeParentId]);

  const activeUserEmail = localStorage.getItem('email')

  const handleDiscussionChange = () => {
    setIsEditDiscussion(true)
  }

  const handleDiscussionDelete = () => {
  }

  const handleDiscussionChangeSave = async () => {
    setIsEditDiscussion(false)
    try {
      const { data, status } = await axios.put(
        `${process.env.REACT_APP_QA_API}/answer/${discussion.id}`,
        discussion,
        { headers }
      );
      if (status === 200) {
        console.log('success')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDiscussionChangeCancel = () => {
    setIsEditDiscussion(false)
  }

  const handleAnswerChange = (answer) => {
    setAnswer_(answer)
    setIsEditAnswer(true)
  }

  const handleAnswerDelete = () => {
  }

  const handleAnswerChangeSave = async () => {
    setIsEditAnswer(false)
    try {
      const { data, status } = await axios.put(
        `${process.env.REACT_APP_QA_API}/discussion/answer/${answer_.id}`,
        answer_,
        { headers }
      );
      if (status === 200) {
        console.log('success')
        apiCall()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAnswerChangeCancel = () => {
    setIsEditAnswer(false)
  }

  const onBlockNotificationEventChange = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_QA_API}/discussion/${discussion.id}`,
        {'notify_block': !checked},
        { headers }
      );
      setChecked(!checked);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {loading && <div
      className={`w-full h-screen flex flex-col py-3 md:py-3 md:px-6 overflow-y-scroll ${discussion
        ? "gap-2 rounded-lg bg-black md:bg-white"
        : "gap-6 md:gap-12 "
        }`}
      >Loading...</div>}
      {!loading && (
        <div
          className={`w-full h-screen flex flex-col py-3 md:py-3 md:px-6 overflow-y-scroll ${discussion
            ? "gap-2 rounded-lg bg-black md:bg-white"
            : "gap-6 md:gap-12 "
            }`}
        >
          {topicData.length > 0 && (
            <div
              className={`flex items-center gap-2 md:gap-4 text-2xl font-bold text-gray-g1 ${discussion && "hidden md:flex"
                }`}
            >
              <Link to={`/${topicData[0]?.topic?.category.toLowerCase()}`}>
                <img
                  src={leftArrow}
                  alt="left"
                  className="p-2 hover:bg-gray-g10 rounded-full"
                />
              </Link>
              {topicData[0]?.topic?.category}
            </div>
          )}

          <div className="mx-auto mb-4 w-full h-full flex flex-col gap-6 py-4 rounded bg-white ">
            <div className="flex items-center">
              <img
                src={back}
                alt=""
                className="w-12 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <span className="font-bold text-lg">
                {topicData[0]?.topic?.title}
              </span>
            </div>
            {discussionData.map(({ answers, creator }, index) => (
              <div key={`discussion-ans-data-${index}`}>
                <div className="py-4 px-4 flex flex-col gap-4 rounded-lg bg-gray-g10">
                  <div className="flex justify-between">
                    <div className="flex gap-4 items-center">
                      {/* <img src={Profile} alt="Profile" className="w-8" /> */}
                      <div className="font-bold">{ creator.username }</div>
                      <div className="text-gray-g11">
                        {moment(discussion.created_at).toLocaleString()}
                      </div>
                    </div>
                    {isAdmin ? (
                      <div className="flex gap-4 items-center text-sm">
                        {isEditDiscussion ? (
                          <>
                            <div className="text-green-g1 cursor-pointer" onClick={() => {
                              handleDiscussionChangeSave()
                            }}>
                              Save
                            </div>
                            <div className="text-red-r1 cursor-pointer" onClick={() => handleDiscussionChangeCancel()}>
                              Cancel
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-green-g1 cursor-pointer" onClick={() => {
                              handleDiscussionChange()
                            }}>
                              Edit
                            </div>
                            {/* <div className="text-red-r1 cursor-pointer" onClick={() => handleDiscussionDelete()}>
                              Delete
                            </div> */}
                          </>
                        )}
                      </div>
                    ) : (
                      activeUserEmail === discussion.creator.email && <div className="flex gap-4 items-center text-sm">
                        {isEditDiscussion ? (
                          <>
                            <div className="text-green-g1 cursor-pointer" onClick={() => {
                              handleDiscussionChangeSave()
                            }}>
                              Save
                            </div>
                            <div className="text-red-r1 cursor-pointer" onClick={() => handleDiscussionChangeCancel()}>
                              Cancel
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-green-g1 cursor-pointer" onClick={() => {
                              handleDiscussionChange()
                            }}>
                              Edit
                            </div>
                            {/* <div className="text-red-r1 cursor-pointer" onClick={() => handleDiscussionDelete()}>
                              Delete
                            </div> */}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="justify-content text-sm font-bold">
                    {isEditDiscussion === true ? (
                      <textarea
                        placeholder="Type Discussion here..."
                        className="py-4 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-lg w-full my-4"
                        value={discussion.content}
                        onChange={(e) => setDiscussion({...discussion, content: e.target.value})}
                      />
                    ) : (
                      discussion.content
                    )}
                  </div>
                </div>
                {Number(userId) === discussion.creator ? (
                  <div className="md:my-4 my-8 flex flex-col gap-4">
                    <label className="text-sm text-semibold text-justify">
                      <input
                        className="mx-2"
                        type="checkbox"
                        checked={checked}
                        onChange={onBlockNotificationEventChange}
                      />
                      Block email notification for new replies.
                    </label>
                  </div>
                ) : ''}
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setComment("");
                    setSubmittedComment(comment);
                    handleAnswerSubmissionByButton();
                  }}
                >
                  <textarea
                    placeholder="Type answer here..."
                    className="py-4 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-lg w-full my-4"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    onKeyDown={handleAnswerSubmission}
                  />
                  <input type="submit" value="Add answer" className="px-2 py-1 bg-green-g1 text-xs float-right rounded-full font-semibold text-white cursor-pointer"
                  />
                </form>
                <div className="font-bold text-lg">Answers</div>
                {answers.length > 0 &&
                  answers.map(({ answer, creator }, index) => (
                    <>
                      <div
                        className="py-4 px-4 my-4 flex flex-col gap-4 rounded-lg bg-gray-g10 mt-8"
                        key={`discussion-answer-${index}`}
                      >
                        <div className="flex gap-4 items-center cursor-pointer"
                          onClick={() => {
                            setIsEditComment(false)
                            setactiveParentId(answer.id)
                            setActiveChildId(answer.parent_answer)
                          }}
                        >
                          {/* <img src={Profile} alt="Profile" className="w-8" /> */}
                          <div className="font-bold">{ creator.username }</div>
                          <div className="text-gray-g11">
                            {moment(answer.created_at).toLocaleString()}
                          </div>
                        </div>
                        <div className="flex justify-end">
                          <div className="flex gap-4 items-center text-sm">
                            {isAdmin ? (
                              <>
                                {isEditAnswer ? (
                                  <>
                                    <div className="text-green-g1 cursor-pointer" onClick={() => {
                                      handleAnswerChangeSave()
                                    }}>
                                      Save
                                    </div>
                                    <div className="text-red-r1 cursor-pointer" onClick={() => handleAnswerChangeCancel()}>
                                      Cancel
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="text-green-g1 cursor-pointer" onClick={() => {
                                      handleAnswerChange(answer)
                                    }}>
                                      Edit
                                    </div>
                                    {/* <div className="text-red-r1 cursor-pointer" onClick={() => handleAnswerDelete()}>
                                      Delete
                                    </div> */}
                                  </>
                                )}
                              </>
                            ) : (
                              activeUserEmail === creator.email && <>
                                {isEditAnswer ? (
                                  <>
                                    <div className="text-green-g1 cursor-pointer" onClick={() => {
                                      handleAnswerChangeSave()
                                    }}>
                                      Save
                                    </div>
                                    <div className="text-red-r1 cursor-pointer" onClick={() => handleAnswerChangeCancel()}>
                                      Cancel
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="text-green-g1 cursor-pointer" onClick={() => {
                                      handleAnswerChange(answer)
                                    }}>
                                      Edit
                                    </div>
                                    {/* <div className="text-red-r1 cursor-pointer" onClick={() => handleAnswerDelete()}>
                                      Delete
                                    </div> */}
                                  </>
                                )}
                              </>
                            )}
                            
                          </div>
                        </div>
                        <div className="justify-content text-sm">
                          {isEditAnswer === true ? (
                            <textarea
                              placeholder="Type Answer here..."
                              className="py-4 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-lg w-full my-4"
                              value={answer_.content}
                              onChange={(e) => setAnswer_({...answer_, content: e.target.value})}
                            />
                          ) : (answer.content)}
                        </div>
                      </div>
                      {childAnswers.length > 0 &&
                        answer.id === activeParentId &&
                        <>
                          <div className="flex justify-end">
                            <div className="w-11/12 relative">
                              <img
                                src={curvedArrow}
                                alt=""
                                className="absolute -left-10"
                              />
                              {childAnswers.map((item, index) => (
                                <div className="py-4 px-4 flex flex-col my-2 gap-4 rounded-lg bg-gray-g10"
                                  key={`discussion-child-ans-${index}`}
                                >
                                  <div className="flex justify-between">
                                    <div className="flex gap-4 items-center">
                                      {/* <img src={Profile} alt="Profile" className="w-8" /> */}
                                      <div className="font-bold">{ creator.username }</div>
                                      <div className="text-gray-g11">
                                        {moment(item.answer.created_at).toLocaleString()}
                                      </div>
                                      {/* {activeUserEmail}
                                      {item.creator.email} */}
                                    </div>
                                    {isAdmin ? (
                                      <div className="flex gap-4 items-center text-sm">
                                        <div className="text-green-g1 cursor-pointer" onClick={() => {
                                          setAnswerComment(item.answer.content)
                                          setIsEditComment(true)
                                          setActiveChildId(item.answer.id)
                                        }
                                        }>
                                          Edit
                                        </div>
                                        <div className="text-red-r1 cursor-pointer" onClick={() => handleSubAnswerDelete(item.answer.id)}>
                                          Delete
                                        </div>
                                      </div>
                                    ) : (
                                      activeUserEmail === item.creator.email && <div className="flex gap-4 items-center text-sm">
                                        <div className="text-green-g1 cursor-pointer" onClick={() => {
                                          setAnswerComment(item.answer.content)
                                          setIsEditComment(true)
                                          setActiveChildId(item.answer.id)
                                        }
                                        }>
                                          Edit
                                        </div>
                                        <div className="text-red-r1 cursor-pointer" onClick={() => handleSubAnswerDelete(item.answer.id)}>
                                          Delete
                                        </div>
                                      </div>
                                    )
                                    }
                                  </div>
                                  <div className="justify-content text-sm">
                                    {item.answer.content}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </>
                      }
                      {activeParentId === answer.id && (
                        <form onSubmit={(e) => {
                          e.preventDefault();
                          handleChildAnswerSubmissionByButton();
                        }}
                        >
                          <div className="flex justify-end">
                            <textarea
                              placeholder="Type answer here..."
                              className="w-11/12 py-4 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-lg my-4"
                              value={answerComment}
                              onChange={(e) => setAnswerComment(e.target.value)}
                              onKeyDown={handleChildAnswerSubmission}
                            />
                          </div>
                          <input type="submit" value="Add answer" className="px-2 py-1 bg-green-g1 text-xs float-right rounded-full font-semibold text-white cursor-pointer"
                          />
                        </form>
                      )}
                    </>
                  ))}
                {loadingAnswer && <div>Loading...</div>}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Discussion;
import React from "react";
import Logo from "./images/logo.svg";
import Banner from "../../../../src/banner.png"

const Footer = ({setShowTermsModal, setShowSupportModal}) => {
  return (
    <>
      <div className="">
        <img src={Banner} className="max-w-full h-auto" />
      </div>
      <div className=" bg-gray-g1 py-8 px-8 flex flex-col gap-8 md:flex-row items-center justify-between">
        <img src={Logo} alt="Logo" className="w-48" />
        <nav className="flex flex-wrap justify-center -mx-5 -my-2">
            <div className="px-5 py-2">
                <button className="text-base leading-6 text-white hover:text-gray-300" onClick={() => setShowSupportModal(true)}>
                    Support
                </button>
            </div>
            {/* <div className="px-5 py-2">
                <button className="text-base leading-6 text-white hover:text-gray-300">
                    About Us
                </button>
            </div>
            <div className="px-5 py-2">
                <button className="text-base leading-6 text-white hover:text-gray-300">
                    Careers
                </button>
            </div> */}
            <div className="px-5 py-2">
                <button className="text-base leading-6 text-white hover:text-gray-300" onClick={() => setShowTermsModal(true)}>
                    Terms
                </button>
            </div>
            {/* <div className="px-5 py-2">
                <button className="text-base leading-6 text-white hover:text-gray-300">
                    Privacy
                </button>
            </div> */}
        </nav>
        <div className="text-white text-sm">
          © 2023 QuantFinancePrep. All Rights reserved.
        </div>
      </div>
    </>
  );
};

export default Footer;

import React from "react";

import { SideBar } from "../../local";
import 'react-toastify/dist/ReactToastify.css'


const Dashboard = ({children}) => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <div className="md:flex">
        <SideBar open={open} setOpen={setOpen} />
        {!open && <>{children}</>}
        
      </div>
    </>
  );
};

export default Dashboard;

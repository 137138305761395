import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import axios from 'axios';

import logo from "./images/logo.svg";
import Calculator from "./images/Calculator.svg";
import PieChart from "./images/pieChart.svg";
import HashTag from "./images/HashTag.svg";
import Logout from "./images/Logout.svg";
import Setting from "./images/Setting.svg";
import Menu from "./images/Menu.svg";
import { useEffect } from "react";
import { MainContext } from "../../../../App";

const TAB_LIST_ARRAY = [
  {
    id: 0,
    title: "Statistics",
    icon: PieChart,
    href: "/statistics",
  },
  {
    id: 1,
    title: "Finance",
    icon: Calculator,
    href: "/finance",
  },
  {
    id: 2,
    title: "Coding",
    icon: HashTag,
    href: "/coding",
  },
  {
    id: 3,
    title: "Log out",
    icon: Logout,
    href: "/",
  },
  {
    id: 4,
    title: "Settings",
    icon: Setting,
    href: "/settings",
  },
];

const SideBar = ({ open, setOpen }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false)

  const context =React.useContext(MainContext)

  useEffect(() => {
    const topicType =pathname.split('/')[1]
    if(topicType === 'statistics') {
      setSelectedTab(0)
      return;
    }
    if(topicType === 'finance') {
      setSelectedTab(1)
      return;
    }
    if(topicType === 'coding') {
      setSelectedTab(2)
      return;
    }
    if(topicType === 'settings') {
      setSelectedTab(4)
      return;
    }
  }, [pathname])
  const handleLogout = async () => {
    const token = localStorage.getItem('token')
    try {
      setLoading(true)
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token} `
      }
      await axios.post(`${process.env.REACT_APP_QA_API}/logout`, {}, {headers})
      setLoading(false)
      localStorage.clear()
      context.login.setIsLoggedIn(false)
      navigate('/')

    } catch (error) {
      setLoading(false)
    }
  }
  return (
    <>
      {/* Mobile device */}
      <div
        className={`text-white ${open && "h-screen flex justify-between flex-col bg-gray-g1 "
          }`}
      >
        <div>
          {loading && <div>Loading...</div>}
          <div className="md:hidden bg-gray-g1 flex px-4 py-4 md:mx-0 justify-between md:justify-center items-center ">
            <Link to="/">
              <img src={logo} alt="logo" className="w-48" />
            </Link>
            <img
              src={Menu}
              alt="menu"
              className={` ${open && " p-2 bg-gray-g8 rounded-lg cursor-pointer"
                }`}
              onClick={() => setOpen(!open)}
            />
          </div>

          <div
            className={`${open ? "md:hidden mx-4 my-8 flex flex-col text-lg" : "hidden"
              } `}
          >
            <TabList
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              open={open}
              setOpen={setOpen}
            />
          </div>
        </div>
        <div
          className={`${open ? "md:hidden mx-4 flex flex-col text-lg" : "hidden"
            } `}
        >
          <div className="px-2 py-3 w-full flex gap-6 rounded-full hover:bg-gray-g8 cursor-pointer" onClick={handleLogout}>
            <img src={Logout} alt="Logout" className="w-5" /> Log out
          </div>
          <Link to='/settings' className={`px-2 py-3 w-full flex gap-6 rounded-full hover:bg-gray-g8 cursor-pointer ${selectedTab === 4 && "bg-gray-g8"}`}>
              <img src={Setting} alt="Setting" className="w-5" />
              Settings
          </Link>
        </div>
        <div />
      </div>
      {/* web view */}
      <div className="hidden md:flex sticky h-screen bg-gray-g1 text-white lg:w-7">
        <button className="border-solid bg-gray-g8 rounded float-right px-2 text-2xl" onClick={() => setIsCollapsed(!isCollapsed)}>{isCollapsed ? "»" : "«"}</button>
      </div>
      <div className={`hidden md:block sticky h-screen w-[30%] ${!isCollapsed ? "lg:w-1/5" : "lg:w-0"} bg-gray-g1 text-white`}>
        <div className="h-full flex justify-between flex-col ">
          {/* 1 */}
          <div className="">
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                className={`hidden ${!isCollapsed && "md:block"} mx-auto my-4 w-48 cursor-pointer`}
              />
            </Link>
            {/* 2 */}
            <div className={`mx-4 my-8 flex flex-col text-lg ${isCollapsed && "hidden"}`}>
              <TabList
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                open={null}
                setOpen={null}
              />
            </div>
          </div>

          {/* 3 */}
          <div className={`mb-6 mx-4 ${isCollapsed && "hidden"}`}>
            <div className={`px-5 py-3 w-full flex gap-6 rounded-full cursor-pointer ${isCollapsed && "hidden"}`} onClick={handleLogout}
            >
              <img src={Logout} alt="icon" className="w-5" /> Log out
            </div>
            <Link to='/settings' className={`px-5 py-3 w-full flex gap-6 rounded-full cursor-pointer ${selectedTab === 4 && "bg-gray-g8"}`}>
              <img src={Setting} alt="Setting" className="w-5" />
              Settings
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;

function TabList({ setSelectedTab, selectedTab, open, setOpen }) {
  const context =React.useContext(MainContext)

  const onChangeTab = (id) => {
    setSelectedTab(id)
    context.setActiveUri('')
    try {
      setOpen(!open)
    } catch {
    }
  }
  return (
    <div className="flex flex-col justify-between">
      <div>
        {TAB_LIST_ARRAY.slice(0, 3).map(({ id, title, icon, href }) => (
          <Link to={href} key={title}>
            <div
              className={`px-5 py-3 w-full flex gap-6 rounded-full cursor-pointer ${selectedTab === id && " bg-gray-g8"
                }`}
              key={id}
              onClick={() => onChangeTab(id)}
            >
              <img src={icon} alt="icon" className="w-5" /> {title}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Eye from "./images/Eye.svg";
import { MainContext } from "../../../../App";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js/pure";
import TermsAndConditionsModal from "./TermsAndConditions";
import CancelSubscriptionConfirmModal from "./CancelSubscriptionConfirm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LegalDisclaimerModal } from "../../../shared/header/Header";

export const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const Setting = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const userId = localStorage.getItem("userId");
  const [userInfo, setUserInfo] = useState({
    email: "",
    username: "",
    linkedin: "",
    referred_by: "",
    job_cities: "",
    phone: "",
    key_words: "",
  });
  const [password, setPassword] = useState({
    newValue: "",
    confirmValue: "",
  });

  const is_edu = email.includes(".edu") && !email.includes("alumni");
  const initPrices = is_edu
    ? {
        "1month": 1000,
        "3months": 2500,
        "6months": 4000,
      }
    : {
        "1month": 4000,
        "3months": 10000,
        "6months": 16000,
      };

  const [error, setError] = useState(false);
  const [profileError, setProfileError] = useState({
    email: "",
    username: "",
    linkedin: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [cardError, setCardError] = useState("");
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);
  const [isInEdit, setIsInEdit] = useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);
  const [priceOptions, setPriceOptions] = useState(initPrices);
  const [checked, setChecked] = useState({
    "1month": false,
    "3months": false,
    "6months": false,
  });

  const context = React.useContext(MainContext);
  const currentURL = window.location.pathname;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token} `,
  };

  async function loadUserInfo() {
    try {
      const { data, status } = await axios.get(
        `${process.env.REACT_APP_QA_API}/user-profile/${userId}`,
        { headers }
      );
      if (status === 200) {
        if (is_edu && data.is_legal_edu === false) {
          context.setShowLegalDisclaimerModal(true);
        }
        setUserInfo(data);
        setSubscriptionType(data.subscription_type);
        context.setIsSubscription(data.is_subscribed);

        switch (data.subscription_type) {
          case "1month":
          case "1month_edu":
            setChecked({
              ...checked,
              "1month": true,
              "3months": false,
              "6months": false,
            });
            break;
          case "3months":
          case "3months_edu":
            setChecked({
              ...checked,
              "1month": false,
              "3months": true,
              "6months": false,
            });
            break;
          case "6months":
          case "6months_edu":
            setChecked({
              ...checked,
              "1month": false,
              "3months": false,
              "6months": true,
            });
            break;
        }
      }
    } catch (error) {
      // console.log(error)
      const statusCode = error.response.status;
      if (statusCode === 400) {
        setError({ ...error, submit: "User does not exist." });
      }
      if (statusCode === 401) {
        context.setIsLoggedIn(false);
        localStorage.clear();
        navigate("/");
      }
    }
  }

  useEffect(() => {
    setLoading(true);
    loadUserInfo();
    setLoading(false);
  }, []);

  useEffect(() => {
    // console.log(currentURL)
    if (currentURL !== "/settings") {
      toast.warn("Must be subscribed to view content.");
    }
  }, [currentURL]);

  const handlePasswordReset = async () => {
    setSuccess(false);
    setError("");
    if (
      !password.newValue ||
      !password.confirmValue ||
      password.newValue !== password.confirmValue
    ) {
      setError(
        "Error: The confirm password doesn't match with the new password!"
      );
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token} `,
    };
    const formData = {
      password: password.newValue,
    };
    try {
      setLoading(true);
      if (token) {
        await axios.post(
          `${process.env.REACT_APP_QA_API}/reset-password/${token}/${email}`,
          formData,
          { headers }
        );
        setLoading(false);
        setSuccess(true);
        setError("");
        setPassword({
          newValue: "",
          confirmValue: "",
        });
        context.setIsLoggedIn(false);
        navigate("/");
        localStorage.clear();
        context.setLoginModal(true);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error.response.status === 401) {
        context.setIsLoggedIn(false);
        localStorage.clear();
        navigate("/");
      }
      setSuccess(false);
      setError("Some error occured. Please reload your page.");
    }
  };

  const handleSubscription = async () => {
    const stripe = await stripePromise
    try {
      const {data, status} = await axios.post(
        `${process.env.REACT_APP_QA_API}/payments/create-checkout-session`,
        {'subscriptionType': subscriptionType},
        { headers }
      );
      if (status == 200) {
        stripe.redirectToCheckout({sessionId: data.sessionId})
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelSubscription = async () => {
    setShowCancelSubscriptionModal(true);
  };

  const confirmCancelSubscription = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token} `,
      };

      const { data, status } = await axios.delete(
        `${process.env.REACT_APP_QA_API}/payments/cancel-subscription/${userInfo.subscription_id}`,
        { headers }
      );

      if (status === 200) {
        setUserInfo({ ...userInfo, is_subscribed: false });
        localStorage.setItem("is_subscribed", false);
        context.setIsSubscription(false);
        setIsAcceptedTerms(false);
        setChecked({ "1month": false, "3months": false, "6months": false });
        setSubscriptionType(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubscriptionTypeChange = async (checkId) => {
    if (checkId === "1month") {
      if (is_edu) {
        setSubscriptionType("1month_edu");
      } else {
        setSubscriptionType("1month");
      }
      setChecked({
        ...checked,
        "1month": !checked["1month"],
        "3months": false,
        "6months": false,
      });
    }
    if (checkId === "3months") {
      if (is_edu) {
        setSubscriptionType("3months_edu");
      } else {
        setSubscriptionType("3months");
      }
      setChecked({
        ...checked,
        "1month": false,
        "3months": !checked["3months"],
        "6months": false,
      });
    }
    if (checkId === "6months") {
      if (is_edu) {
        setSubscriptionType("6months_edu");
      } else {
        setSubscriptionType("6months");
      }
      setChecked({
        ...checked,
        "1month": false,
        "3months": false,
        "6months": !checked["6months"],
      });
    }
  };

  const confirmSubscription = async () => {
    if (subscriptionType === null) {
      setCardError("Please select subscription type.");
      return;
    }
    if (email === "" || email === undefined || email === null) {
      setCardError("Valid card information required to subscribe.");
      return;
    }
    if (!isAcceptedTerms) {
      setShowTermsModal(true);
    } else {
      handleSubscription();
    }
  };

  const updateProfile = async () => {
    if (!userInfo.email) {
      setProfileError({ ...profileError, email: "Email is required" });
      return;
    }
    if (!userInfo.username) {
      setProfileError({ ...profileError, username: "Username is required" });
      return;
    }
    if (!userInfo.linkedin) {
      setProfileError({ ...profileError, linkedin: "Linkedin is required" });
      return;
    }
    try {
      const { data, status } = await axios.put(
        `${process.env.REACT_APP_QA_API}/user-profile/${userId}`,
        userInfo,
        { headers }
      );
      if (status === 200) {
        setIsInEdit(false);
        setProfileError({
          email: "",
          username: "",
          linkedin: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setProfileError({
      email: "",
      username: "",
      linkedin: "",
    });
  }, [isInEdit]);

  return (
    <>
      <div
        className={`w-full h-screen bg-gray-g5 flex flex-col py-3 md:py-3 md:px-6 overflow-y-scroll`}
      >
        <div className="w-full h-screen">
          <div className="my-2 px-2 text-2xl font-bold ">Profile settings</div>
          <div className="flex flex-col gap-6 py-6 px-2  md:px-20  md:-mx-6 bg-white">
            {loading && <div>loading...</div>}
            {success && (
              <div className="text-green-g1">
                Password successfully changed! Please login again!
              </div>
            )}
            {error && <div className="text-red-r1">{error}</div>}
            <div className="w-full flex flex-col md:flex-row justify-between gap-8">
              <div className="md:w-1/2">
                <div className="py-2 text-sm">Email</div>
                <input
                  type="email"
                  placeholder="Type email address..."
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full "
                  value={email}
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, email: e.target.value })
                  }
                />
                {profileError.email && (
                  <div className="text-red-r1 text-sm">
                    {profileError.email}
                  </div>
                )}
              </div>
              <div className="md:w-1/2">
                <div className="py-2 text-sm">Linkedin</div>
                <input
                  type="text"
                  placeholder="Type Linkedin profile URL"
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full "
                  value={userInfo.linkedin === null ? "" : userInfo.linkedin}
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, linkedin: e.target.value })
                  }
                />
                {profileError.linkedin && (
                  <div className="text-red-r1 text-sm">
                    {profileError.linkedin}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between gap-8 mt-4">
              <div className="md:w-1/2">Optional Fields</div>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between gap-8">
              <div className="md:w-1/2">
                <div className="py-2 text-sm">
                  Display name for discussion forums
                </div>
                <input
                  type="text"
                  placeholder="Type username..."
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full "
                  value={userInfo.username === null ? "" : userInfo.username}
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, username: e.target.value })
                  }
                />
                {profileError.username && (
                  <div className="text-red-r1 text-sm">
                    {profileError.username}
                  </div>
                )}
              </div>
              <div className="md:w-1/2">
                <div className="py-2 text-sm">Referred By (email address)</div>
                <input
                  type="email"
                  placeholder="Type email address..."
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full "
                  value={
                    userInfo.referred_by === null ? "" : userInfo.referred_by
                  }
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, referred_by: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between gap-8">
              <div className="md:w-1/2">
                <div className="py-2 text-sm">{"Desired job cities"}</div>
                <input
                  type="text"
                  placeholder="Type desired job cities..."
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full "
                  value={
                    userInfo.job_cities === null ? "" : userInfo.job_cities
                  }
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, job_cities: e.target.value })
                  }
                />
              </div>
              <div className="md:w-1/2">
                <div className="py-2 text-sm">Phone Number</div>
                <input
                  type="email"
                  placeholder="Type phone number..."
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full "
                  value={userInfo.phone === null ? "" : userInfo.phone}
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, phone: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="w-full">
              <div className="md:w-1/2">
                <div className="py-2 text-sm">Keywords for recruiters</div>
                <textarea
                  type="text"
                  placeholder="Add key words..."
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-md"
                  value={userInfo.key_words === null ? "" : userInfo.key_words}
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, key_words: e.target.value })
                  }
                />
              </div>
            </div>
            {isInEdit ? (
              <div className="py-2 flex justify-end px-2 md:px-14">
                <div
                  className="px-6 py-2 mx-2 bg-green-g1 rounded-full font-semibold text-white cursor-pointer"
                  onClick={() => updateProfile()}
                >
                  Save
                </div>
                <div
                  className="px-6 py-2 mx-2 bg-green-g1 rounded-full font-semibold text-white cursor-pointer"
                  onClick={() => setIsInEdit(false)}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className="py-2 flex justify-end px-2 md:px-14">
                <div
                  className="px-6 py-2 bg-green-g1 rounded-full font-semibold text-white cursor-pointer"
                  onClick={() => setIsInEdit(true)}
                >
                  Edit profile
                </div>
              </div>
            )}
          </div>
          <div className="my-2 px-2 text-2xl font-bold ">Change Password</div>
          <div className="flex flex-col gap-6 py-6 px-2  md:px-20  md:-mx-6 bg-white">
            <div className="w-full flex flex-col md:flex-row justify-between gap-8">
              <div className="md:w-1/2">
                <div className="py-2 text-sm">New Password</div>
                <div className="flex">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Type here..."
                    className="py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full w-full"
                    value={password.newValue}
                    onChange={(e) =>
                      setPassword({ ...password, newValue: e.target.value })
                    }
                  />
                  <img
                    src={Eye}
                    alt="ShowPassword"
                    className="w-6 -ml-10 cursor-pointer"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  />
                </div>
              </div>

              <div className="md:w-1/2">
                <div className="py-2 text-sm">Confirm Password</div>
                <div className="flex">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Type here..."
                    className="py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full w-full"
                    value={password.confirmValue}
                    onChange={(e) =>
                      setPassword({ ...password, confirmValue: e.target.value })
                    }
                  />
                  <img
                    src={Eye}
                    alt="ShowPassword"
                    className="w-6 -ml-10 cursor-pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </div>
              </div>
            </div>
            <div className="py-2 md:py-8 flex justify-end px-2 md:px-14">
              <div
                className="px-6 py-2 bg-green-g1 rounded-full font-semibold text-white cursor-pointer"
                onClick={handlePasswordReset}
              >
                Save Password
              </div>
            </div>
          </div>
          <div className="my-2 px-2 text-2xl font-bold ">Subscription</div>
          <div className=" gap-4 py-2 px-2 md:px-14 md:-mx-6 bg-white text-red-r1">
            {cardError}
          </div>
          <div className="flex flex-col md:flex-row items-center gap-14 py-6 px-2 md:px-14 md:-mx-6 bg-white">
            <div className="w-3/5">
              <div
                className={`py-6 md:px-10 bg-gray-g5 text-center ${
                  checked["1month"] &&
                  context.isSubscription &&
                  "border-dotted border-2 border-green-g1"
                }`}
              >
                <input
                  checked={checked["1month"]}
                  type="checkbox"
                  onChange={() => handleSubscriptionTypeChange("1month")}
                  disabled={context.isSubscription ? true : false}
                />
                <div className="text-green-g1 text-2xl font-bold">
                  {"$" + priceOptions["1month"]}
                </div>
                <div className="text-xl">per month</div>
              </div>
            </div>
            <div className="w-3/5">
              <div
                className={`py-6 md:px-10 bg-gray-g5 text-center ${
                  checked["3months"] &&
                  context.isSubscription &&
                  "border-dotted border-2 border-green-g1"
                }`}
              >
                <input
                  checked={checked["3months"]}
                  type="checkbox"
                  onChange={() => handleSubscriptionTypeChange("3months")}
                  disabled={context.isSubscription ? true : false}
                />
                <div className="text-green-g1 text-2xl font-bold">
                  {"$" + priceOptions["3months"]}
                </div>
                <div className="text-xl">every 3 months</div>
              </div>
            </div>
            <div className="w-3/5">
              <div
                className={`py-6 md:px-10 bg-gray-g5 text-center ${
                  checked["6months"] &&
                  context.isSubscription &&
                  "border-dotted border-2 border-green-g1"
                }`}
              >
                <input
                  checked={checked["6months"]}
                  type="checkbox"
                  onChange={() => handleSubscriptionTypeChange("6months")}
                  disabled={context.isSubscription ? true : false}
                />
                <div className="text-green-g1 text-2xl font-bold">
                  {"$" + priceOptions["6months"]}
                </div>
                <div className="text-xl">every 6 months</div>
              </div>
            </div>
          </div>
          <div className="py-2 md:py-8 flex justify-end px-2 md:px-14">
            {context.isSubscription ? (
              <>
                <div
                  className="px-6 py-2 bg-green-g1 mx-2 rounded-full font-semibold text-white cursor-pointer"
                  onClick={(e) => cancelSubscription()}
                >
                  Change Subscription
                </div>
              </>
            ) : (
              <div
                className="px-6 py-2 bg-green-g1 rounded-full font-semibold text-white cursor-pointer"
                onClick={(e) => confirmSubscription()}
              >
                Choose Subscription
              </div>
            )}
          </div>
        </div>
      </div>
      {showTermsModal && (
        <TermsAndConditionsModal
          setShowModal={setShowTermsModal}
          setIsAcceptedTerms={setIsAcceptedTerms}
          handleSubscription={handleSubscription}
          acceptable={true}
        />
      )}
      {showCancelSubscriptionModal && (
        <CancelSubscriptionConfirmModal
          setShowCancelSubscriptionModal={setShowCancelSubscriptionModal}
          confirmCancelSubscription={confirmCancelSubscription}
        />
      )}
      {context.showLegalDisclaimerModal && <LegalDisclaimerModal />}
      <ToastContainer />
    </>
  );
};

export default Setting;

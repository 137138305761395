import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "./components/local/landing/LandingPage";
import Dashboard from "./components/local/dashboard/Dashboard";
import ConfirmationPage from "./components/local/confirmation/ConfirmationPage";
import _404Page from "./components/local/_404/_404Page";
import {
  Theme,
  Setting,
  Discussion,
  DiscussionList,
  Table
} from "./components/local";
import ResetPasswordPage from "./components/local/reset_password/ResetPasswordPage";

export const MainContext = React.createContext()

export default function App() {
  const token = localStorage.getItem("token")
  const [isLoggedIn, setIsLoggedIn] = useState(token ? true : false);
  const [activeUri, setActiveUri] = useState('')
  const login = {isLoggedIn, setIsLoggedIn}
  const [loginModal, setLoginModal] = React.useState(false);
  const [verifyOTPModal, setVerifyOTPModal] = React.useState(false);
  const [loginLimitedModal, setLoginLimitedModal] = React.useState(false);
  const [showLegalDisclaimerModal, setShowLegalDisclaimerModal] = React.useState(false);
  const [isNewDevice, setIsNewDevice] = useState(localStorage.getItem("is_new_device"))

  const is_2fa = localStorage.getItem("is_2fa") === 'true' ? true : false;
  const [is2faVerified, setIs2faVerified] = useState(localStorage.getItem("is_2fa_verified") === 'true' ? true : false)
  const [isSubscription, setIsSubscription] = useState(localStorage.getItem("is_subscribed") === 'true' ? true : false);

  const [isAdmin, setIsAdmin] = useState(localStorage.getItem("is_admin") === 'true' ? true : false)
  const [isLoginLimited, setIsLoginLimited] = useState(false)
  useEffect(() => {
    if (!token) {
      localStorage.clear()
    }
  }, []);
  return (
    <>
    <MainContext.Provider value={{
      login, 
      setIsLoggedIn, 
      activeUri, 
      setActiveUri, 
      loginModal, 
      setLoginModal,
      verifyOTPModal,
      setVerifyOTPModal,
      isSubscription,
      setIsSubscription,
      isAdmin,
      setIsAdmin,
      isNewDevice,
      setIsNewDevice,
      is2faVerified,
      setIs2faVerified,
      isLoginLimited,
      setIsLoginLimited,
      loginLimitedModal,
      setLoginLimitedModal,
      showLegalDisclaimerModal,
      setShowLegalDisclaimerModal
      }}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/confirmation/:token/:username" element={< ConfirmationPage/>} />
          <Route exact path="/reset-password/:token/:username" element={< ResetPasswordPage/>} />
          {is_2fa && is2faVerified && isLoggedIn && isSubscription && (
            <>
              <Route
                path="statistics"
                element={
                  <Dashboard>
                    <Table />
                  </Dashboard>
                }
              />
              <Route
                path="finance"
                element={
                  <Dashboard>
                    <Table />
                  </Dashboard>
                }
              />
              <Route
                path="coding"
                element={
                  <Dashboard>
                    <Table />
                  </Dashboard>
                }
              />
              <Route
                path=":topicType/:topicId"
                element={
                  <Dashboard>
                    <Theme />
                  </Dashboard>
                }
              />
              <Route
                path="settings"
                element={
                  <Dashboard>
                    <Setting />
                  </Dashboard>
                }
              />
              <Route
                path="discussions"
                element={
                  <Dashboard>
                    <DiscussionList />
                  </Dashboard>
                }
              />
              <Route
                path=":topicType/discussion/:topicId/:discussionId"
                element={
                  <Dashboard>
                    <Discussion />
                  </Dashboard>
                }
              />
            </>
          )}
          {is_2fa && is2faVerified && isLoggedIn && !isSubscription && (
            <>
              <Route
                path="settings"
                element={
                  <Dashboard>
                    <Setting />
                  </Dashboard>
                }
              />
              <Route path="*"
                 element={
                  <Dashboard>
                    <Setting />
                  </Dashboard>
                }
              />
            </>
          )}
          {is_2fa && !is2faVerified && isLoggedIn && (
            <Route path="*" element={(<Navigate replace to="/" />)} />
          )}
          {is_2fa && is2faVerified && isLoggedIn && (
            <Route path="/" element={(<Navigate replace to="/statistics" />)} />
          )}
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={(<Navigate replace to="/404" />)} />
          <Route path="/404" element={<_404Page />} />
        </Routes>
      </BrowserRouter>
    </MainContext.Provider>
    </>
  );
}